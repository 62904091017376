<template>
  <div>
    <v-row class="justify-space-between align-center pb-3">
      <span class="font-weight-bold">{{ this.$props.TaskTypeName }}</span>
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon color="black">mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-dialog v-model="dialog1" width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on" v-ripple="{ center: true }">
                ADD
              </v-list-item>
            </template>
            <v-card class="px-5 py-5">
              <AddTask :statusName="this.$props.TaskTypeName" />
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialog2" width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on" v-ripple="{ center: true }">
                EDIT
              </v-list-item>
            </template>
            <v-card class="px-5 py-5">
              <EditTask :statusName="this.$props.TaskTypeName" />
            </v-card>
          </v-dialog>
          
          <router-link
            style="color: #000"
            class="text-decoration-none"
            to="/task/create-board"
          >
            <v-list-item v-ripple="{ center: true }">
              DELETE
            </v-list-item>
          </router-link>
        </v-list>
      </v-menu>
    </v-row>
    <div class="bg-content" :style="cssVars">
      <div class="py-5">
        <TaskUnit :color="this.$props.color" />
      </div>
      <div class="py-5">
        <TaskUnit :color="this.$props.color" />
      </div>
    </div>
  </div>
</template>

<script>
import AddTask from "../Board/AddTask.vue";
import EditTask from "../Board/EditTask.vue";
import TaskUnit from "./TaskUnit.vue";

export default {
  props: ["TaskTypeName", "color", "color1"],
  data: () => {
    return {
      dialog1: false,
      dialog2: false,

    };
  },
  computed: {
    cssVars() {
      return {
        /* variables you want to pass to css */
        "--color": this.color1,
      };
    },
  },
  components: {
    AddTask,
    TaskUnit,
    EditTask
  },
};
</script>
<style scoped>
.urgent-status {
  background: red;
  color: #fff;
  border-radius: 20px;
  font-weight: bold;
  padding: 0px 10px;
}
.normal-status {
  background: #0bd707;
  color: #fff;
  border-radius: 20px;
  font-weight: bold;
  padding: 0px 10px;
}
.bg-content {
  background-color: var(--color);
}
</style>
